import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './main/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PresentationComponent } from './main/section-presentation/presentation.component';
import { MeetingsComponent } from './main/section-meetings/meetings.component';
import { EventsComponent } from './main/section-events/events.component';
import { SermonsComponent } from './main/section-sermons/sermons.component';
import { DeclarationComponent } from './pages/declaration-page/declaration.component';
import { KnowusComponent } from './pages/knowus-page/knowus.component';
import { ResourcesComponent } from './pages/resources-page/resources.component';

import { RouterModule, Routes } from '@angular/router';
import { InternalHeaderComponent } from './internalheader/internalheader.component';

const routes: Routes = [
  {path:'', redirectTo:'/home', pathMatch:'full'},
  {path:'home', component:HomeComponent},
  {path:'declaration', component:DeclarationComponent},
  {path:'knowus', component:KnowusComponent},
  {path:'resources', component:ResourcesComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    InternalHeaderComponent,
    FooterComponent,
    PresentationComponent,
    MeetingsComponent,
    EventsComponent,
    SermonsComponent,
    DeclarationComponent,
    KnowusComponent,
    ResourcesComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
