<app-internalheader></app-internalheader>

<section class="resources">
    <div class="presentacion">
        <div class="texto-presentacion">
            <h1>Recursos</h1>
        </div>
        <div class="wave" style="height: 170px; overflow: hidden;">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none"
                style="height: 100%; width: 100%;">
                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                    style="stroke: none; fill: #fff;">
                </path>
            </svg>
        </div>
    </div>
</section>