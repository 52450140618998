<section class="predicas">
    <h2 class="titulo aos-init" data-aos="fade-down">Serie de Predicaciones</h2>
    <div class="tarjetas">
        <a class="sermon aos-init" data-aos="zoom-in" href="https://www.youtube.com/watch?v=_1T70ewkM_U&list=PLqHR7ascwg2XreAdVnxq_k0gLCC4chj0x" target="_blank">
            <div class="sermon-content">
                <img src="/assets/img/predicas/video-6.jpg" >
                <div class="texto-sermon">
                    <h4>Finanzas</h4>
                    <span>Serie Finanzas</span>
                </div>
            </div>
        </a>
        <a class="sermon aos-init" data-aos="zoom-in" href="https://www.youtube.com/watch?v=elG9xebksVM&list=PLqHR7ascwg2UQZo-MvhPjzNpywf01CEKd" target="_blank"> 
            <div class="sermon-content">
                <img src="/assets/img/predicas/video-8.jpg" >
                <div class="texto-sermon">
                    <h4>Hechos</h4>
                    <span>Serie Hechos</span>
                </div>
            </div>
        </a>
        <a class="sermon aos-init" data-aos="zoom-in" href="https://www.youtube.com/watch?v=wP1KgCVpLkI&list=PLqHR7ascwg2XYOT-YMORbqg4IscT97y6s" target="_blank">
            <div class="sermon-content">
                <img src="/assets/img/predicas/video-9.jpg" >
                <div class="texto-sermon">
                    <h4>Mes Misionero</h4>
                    <span>Serie Mes Misionero</span>
                </div>
            </div>
        </a>
        
        <a class="sermon aos-init" data-aos="zoom-in" href="https://www.youtube.com/watch?v=QG2OK1-1zvE&list=PLqHR7ascwg2XX98bRqdJojwRhjn4oE93C" target="_blank">
            <div class="sermon-content">
                <img src="/assets/img/predicas/video-10.jpg" >
                <div class="texto-sermon">
                    <h4>Reunión de hombres</h4>
                    <span>Serie Reunión de Hombres</span>
                </div>
            </div>
        </a>
        <a class="sermon aos-init" data-aos="zoom-in" href="https://www.youtube.com/watch?v=e3Nb4qz3sWY&list=PLqHR7ascwg2V7VTIsCd30HozuigX-1MPT" target="_blank">   
            <div class="sermon-content">
                <img src="/assets/img/mujeres.png" >
                <div class="texto-sermon">
                    <h4>Reunión Femenina</h4>
                    <span>Serie Reunión Femenina</span>
                </div>
            </div>
        </a>
        <a class="sermon aos-init" data-aos="zoom-in" href="https://www.youtube.com/watch?v=izN3_9adQuw&list=PLqHR7ascwg2XKLZQ_kvTrl7c8CQgibxvZ" target="_blank">
            <div class="sermon-content">
                <img src="/assets/img/predicas/video-7.jpg" >
                <div class="texto-sermon">
                    <h4>Taller de aconsejamiento</h4>
                    <span>Serie Taller de aconsejamiento</span>
                </div>
            </div>
        </a>
    </div>
</section>