<section class="eventos">
    <h2 class="titulo">Próximos Eventos</h2>
    <div class="articulos">
        <article>
            <div class="evento">
                <figure>
                    <img src="/assets/img/img-conferencia.jpg" >
                </figure>            
                <div class="cuerpo-evento">
                    <h4>Conferencia</h4>
                    <p>Próxima conferencia en breve...</p>
                    <a href="#" class="contacto">
                        *Para más información dejanos tu contacto.
                    </a>
                </div>
            </div>
        </article>
        <article>
            <div class="evento">
                <figure>
                    <img src="/assets/img/img-camp.jpg" >
                </figure>            
                <div class="cuerpo-evento">
                    <h4>Campamento</h4>
                    <p>El día 1 de Marzo se realizará el primer campamento en el predio de 'Las Mojarras'</p>
                    <a href="#" class="contacto">
                        *Para más información dejanos tu contacto.
                    </a>
                </div>
            </div>
        </article>
        <article>
            <div class="evento">
                <figure>
                    <img src="/assets/img/img-bau.jpg" >
                </figure>            
                <div class="cuerpo-evento">
                    <h4>Bautismos</h4>
                    <p>Los bautismos se realizarán el día 10 de Marzo en el río. Playa a confirmar...</p>
                    <a href="#" class="contacto">
                        *Para más información dejanos tu contacto.
                    </a>
                </div>
            </div>
        </article>
    </div>  
</section>