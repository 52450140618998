<footer class="footer">
    <div class="contenedor-footer">
      <div class="contenedor-ubicacion">
        <h3>Ubicación</h3>
        <p>José ingenieros 418<br />Villa María, Córdoba</p>
        <div class="img-ubicacion">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3368.4758629459975!2d-63.24852798803202!3d-32.40640914470008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cc42dec079210b%3A0x96e88e4ee95feef5!2zSm9zw6kgSW5nZW5pZXJvcyA0MTgsIFZpbGxhIE1hcsOtYSwgQ8OzcmRvYmE!5e0!3m2!1ses-419!2sar!4v1720222485675!5m2!1ses-419!2sar" width="350" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div class="contenedor-contacto">
        <h3>Contáctanos</h3>
        <div class="contenedor-form">
            <form class="row g-2" >
                <div class="col-6">
                  <input type="text" class="form-control" id="inputName" placeholder="Nombre">
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" id="inputLastname" placeholder="Apellido">
                </div>
                <div class="col-6">
                    <input type="number" class="form-control" id="inputNumber" placeholder="Teléfono">
                </div>
                <div class="col-6">
                    <input type="email" class="form-control" id="inputEmail" placeholder="Email">       
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" id="inputAddress" placeholder="Dirección">
                </div>
                <div class="col-12">
                    <textarea class="form-control" placeholder="Mensaje" id="floatingTextarea2" style="height: 100px"></textarea>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-primary">Enviar</button>
                </div>
              </form>
        </div>
      </div>
      <div class="contenedor-redes">
        <h3>Seguinos en</h3>
        <div class="div-redes">
          <a href="https://www.facebook.com/IBMVillamaria/" target="_blank">
            <img class="img-fb" src="/assets/img/img-fb.svg">
          </a>
          <a href="https://www.youtube.com/@iglesiabiblicamisioneravm" target="_blank">
            <img class="img-yt" src="/assets/img/img-youtube.svg">
          </a>
        </div>
      </div>
    </div>
    <div class="textos-footer">
      <h2 class="texto-footer-1">Para la</h2>
      <h2 class="texto-footer-2">gloria de Dios y la salvación de muchos</h2>   
    </div>      
</footer>
