<section id="feature">
    <div class="container-fluid bg-theme-1 px-0 ">
        <div class="row">
            <div class="col-md-12" style="padding: 0px;">
                <div class="embed-responsive embed-responsive-full">

                    <iframe id="video" width="560" height="315" src="https://www.youtube.com/embed/P4mbgoK0TKY?si=nhNsjmlg0xMikIfT&amp;controls=0&amp;mute=1&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;color=white&amp;iv_load_policy=3&amp;playlist=P4mbgoK0TKY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                    
                    
                    <div id="video-overlay" class="m-0" style="transform: translateX(0px);">
                      <img class="aos-init" data-aos="fade-right" src="/assets/img/logo.svg">
                        <div class="video-overlay-inner aos-init" data-aos-delay="300" data-aos="fade-right">                            
                            Para la Gloria de Dios<br>y la salvación de muchos
                        </div>

                        <div class="header-redes aos-init" data-aos-delay="600" data-aos="flip-up">
                            <a target="_blank" href="https://www.facebook.com/IBMVillamaria/"><img src="/assets/img/facebook.svg"></a>
                            <a target="_blank" href="https://youtube.com/@iglesiabiblicamisioneravm"><img src="/assets/img/youtube.svg"></a>
                            <a target="_blank" href="https://wa.me/5491199999999"><img src="/assets/img/whatsapp.svg"></a>
                        </div>

                        <div class="header-address aos-init" data-aos-delay="1200" data-aos="flip-up">
                            <img src="/assets/img/location.svg">
                            <div>
                                <h3>José ingenieros 418</h3>
                                <span>Villa María, Córdoba</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <section id="negative">
    
        <div class="container-fluid px-0" id="promos">
            <div class="container">
                <div id="trigger" class="row separator">
                    <p class="text-white mb-5 text-center aos-init" data-aos="fade-up">
                        Si queres participar de nuestra Escuela Dominical envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.
                    </p>                   
                        
                    <div class="presentation-item col-sm-3 mb-5 aos-init" data-aos="fade-up" data-aos-delay="250">                        
                        <div class="bg-image-16x9 theme-border-radius p-relative overflow-hidden" style="background-image: url(/assets/img/conoce.svg);">
                            <div class="bg-image-inner d-flex align-items-end justify-content-center overflow-hidden">
                                <div class="py-2 px-4" style="border-bottom-right-radius:16px;">
                                    <p class="m-0 text-black" style="font-size: 14px">Conoce a Cristo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="presentation-item col-sm-3 mb-5 aos-init" data-aos="fade-up" data-aos-delay="500">
                        <div class="bg-image-16x9 theme-border-radius p-relative overflow-hidden" style="background-image: url(/assets/img/vive.svg);">
                            <div class="bg-image-inner d-flex align-items-end justify-content-center overflow-hidden">
                                <div class="py-2 px-4" style="border-bottom-right-radius:16px;">
                                    <p class="m-0 text-black" style="font-size: 14px">Vive a Cristo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="presentation-item col-sm-3 mb-5 aos-init" data-aos="fade-up" data-aos-delay="750">
                        <div class="bg-image-16x9 theme-border-radius p-relative overflow-hidden" style="background-image: url(/assets/img/sirve.svg);">
                            <div class="bg-image-inner d-flex align-items-end justify-content-center overflow-hidden">
                                <div class="py-2 px-4" style="border-bottom-right-radius:16px;">
                                    <p class="m-0 text-black" style="font-size: 14px">Sirve a Cristo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="presentation-item col-sm-3 mb-5 aos-init" data-aos="fade-up" data-aos-delay="1000">
                        <div class="bg-image-16x9 theme-border-radius p-relative overflow-hidden" style="background-image: url(/assets/img/reproduce.svg);">
                            <div class="bg-image-inner d-flex align-items-end justify-content-center overflow-hidden">
                                <div class="py-2 px-4" style="border-bottom-right-radius:16px;">
                                    <p class="m-0 text-black" style="font-size: 14px">Reproduce a Cristo</p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    
        <div class="container-fluid px-0">
            <div class="container">
                <div class="row d-flex align-items-center justify-content-start separator">
                    <div class="col-md-12 aos-init" data-aos="fade-right">
                        <div class="text-left">
                            <h1 class="mt-0 mb-5 text-white" style="font-weight: 900;">
                                Últimas Predicaciones
                            </h1>
                            <p class="text-white mb-5 text-left">
                                Podes ver las últimas predicaciones en nuestro canal de <a target="_blank" href="https://www.youtube.com/@iglesiabiblicamisioneravm">Youtube</a>.
                            </p>
                            <div class="header-videos text-left">
                                <a target="_blank" href="https://www.youtube.com/watch?v=bi-8uPy-_I0" class="m-0 mr-sm-5 mb-4">
                                    <img class="video-item aos-init" data-aos="zoom-in" src="/assets/img/predicas/video-1.jpg">
                                    <div class="header-video-detail aos-init" data-aos="zoom-in">
                                        <h3>Las marcas de un cristiano comprometido</h3>
                                        <span>(Hechos 7:17-34)</span>
                                    </div>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/watch?v=gSbMsM-6_C0" class="m-0 mr-sm-5 mb-4">
                                    <img class="video-item aos-init" data-aos="zoom-in" src="/assets/img/predicas/video-2.jpg">
                                    <div class="header-video-detail aos-init" data-aos="zoom-in">
                                        <h3>Los peligros de la autocompasión</h3>
                                        <span>(Salmo 73)</span>
                                    </div>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/watch?v=elG9xebksVM" class="m-0 mr-sm-5 mb-4">
                                    <img class="video-item aos-init" data-aos="zoom-in" src="/assets/img/predicas/video-3.jpg">
                                    <div class="header-video-detail aos-init" data-aos="zoom-in">
                                        <h3>Las Marcas de un mensaje comprometido</h3>
                                        <span>(Hechos 7:1-16)</span>
                                    </div>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/watch?v=1aJGlz4gaRo" class="m-0 mb-4">
                                    <img class="video-item aos-init" data-aos="zoom-in" src="/assets/img/predicas/video-4.jpg">
                                    <div class="header-video-detail aos-init" data-aos="zoom-in">
                                        <h3>El trato de Dios a un hombre infiel</h3>
                                        <span>(Jonas 4: 6 -11)</span>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex align-items-center justify-content-end margin-b-xlg ">
                    <div class="col-md-12 aos-init" data-aos="fade-left">
                        <div class="text-right">
                            <h1 class="mt-0 mb-5 text-white" style="font-weight: 900;">
                                Próximos Eventos
                            </h1>
                            <p class="text-white mb-5 text-right">
                                Te invitamos a ser parte de los próximos eventos, si tenes alguna consulta envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.
                            </p>
                            <div class="event-item text-right aos-init" data-aos="zoom-in">
                                <div class="btn btn-theme-primary-solid btn-sm-block m-0 mb-4">
                                    Conferencia de aconsejamiento<br>
                                    <span style="font-size: 12px;">Del Jueves 1 al Domingo 4 de Agosto</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </section>
</section>
<!--<section class="presentacion">
    <div class="textos-presentacion">
        <h2>Nuestra misión:</h2>
        <h1>“Llevar gloria a Dios a través de un compromiso,<br />
        un servicio y una vida de santidad 
        por medio del estudio de su palabra.”</h1>
    </div>
    <div class="wave" style="height: 170px; overflow: hidden;">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none"
            style="height: 100%; width: 100%;">
            <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                style="stroke: none; fill: #e2f2f7;">
            </path>
        </svg>
    </div>
</section>-->