<app-internalheader></app-internalheader>

<section class="knowus">
    <div class="presentacion">
        <div class="texto-presentacion">
            <h1>Conócenos</h1>
        </div>
        <div class="wave" style="height: 170px; overflow: hidden;">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none"
                style="height: 100%; width: 100%;">
                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                    style="stroke: none; fill: #fff;">
                </path>
            </svg>
        </div>
    </div>
    <div class="contenido"> 
        <div class="menu">
            <ul>
                <li>
                    <a href="#">Nuestra Historia</a>
                </li>
                <li>
                    <a href="#">Nuestra Estrategia</a>
                </li>
                <li>
                    <a href="#">¿Quiénes somos?</a>
                </li>
            </ul>
        </div>
        <div class="texto">
            <h2>NUESTRA ESTRATEGIA DE MADUREZ</h2>
            <p>
                <b>NUESTRA MISIÓN</b>
                Como iglesia, hemos elegido la siguiente frase para expresar la misión que hemos recibido de Dios: Personas creciendo para impactar el mundo. 
                Dios nos ha llamado a crecer espiritualmente, y nos ha dejado Su Palabra para guiarnos constantemente a nuevos niveles de compromiso con Su persona. La iglesia no es un club social para recrear a sus miembros sino una familia que tiene como fin desarrollar a sus miembros para que lleguen a ser todo lo que Dios los creó para ser. Cada personas que desea ser miembro de nuestra iglesia debe saber que constantemente será desafiada por Dios, Su Palabra y el pueblo de Dios a crecer en su compromiso con Él y Su obra, y esperamos que cada año el crecimiento espiritual de cada miembro sea evidente, no solo a la congregación sino también al mundo que observa. 
                Creemos por lo tanto que una vida transformada va a impactar las vidas de las personas con las que se relaciona (su familia, sus vecinos, sus compañeros de trabajo, su ciudad entera y hasta lo último de la tierra). Nuestra iglesia no es una cueva donde refugiarse del mundo, sino una pista de lanzamiento de hombres y mujeres que salgan al mundo perdido y lo transformen con el evangelio de Cristo.
            </p>
            <p>
                <b>NUESTRA VISIÓN</b>
                “Como Iglesia Bíblica Misionera de Villa María soñamos con ver el día cuando todo Argentina sea saturado con Iglesias donde se predique y se viva la Palabra de Dios fielmente produciendo una comunidad donde cada creyente esté adorando a Dios, madurando en el Señor y esté activamente involucrado en el servicio a los santos y la predicación del evangelio, uniendo fuerzas en compañerismo hacia una extensión a todo el mundo a través del inicio de nuevas iglesias, para la gloria de Dios y la salvación de muchos.”
            </p>
            <p>
                <b>NUESTRA ESTRATEGIA</b>
                El siguiente proceso muestra el camino de madurez por el que esperamos guiar a cada persona con la que el Señor nos permite ponernos en contacto con el fin de ver hecha realidad la visión que Dios nos ha dado para Villa María y para el mundo.
            </p>
            <img style="display: none" src="/assets/img/img-estrategy.png" >
        </div>
    </div>
</section>