<header>
  <div class="contenedor-img">
    <a routerLink="/home">
      <img class="img-logo" src="\assets\img\Lockup.svg">
    </a>
  </div>
  <nav>    
    <a class="nav-item" routerLink="/home">Inicio</a>
    <a class="nav-item" routerLink="/declaration">Declaración de fe</a>
    <a class="nav-item" routerLink="/knowus">Conócenos</a>
    <a class="nav-item" routerLink="/resources">Recursos</a>
    <a class="nav-item" routerLink="/">Contacto</a>
    <img class="menu-btn" src="\assets\img\menu.png">
  </nav>
</header>
<div class="menu-mobile">
  <img class="close-btn" src="\assets\img\close.png">
  <nav>
    <a class="nav-item" routerLink="/home">Inicio</a>
    <a class="nav-item" routerLink="/declaration">Declaración de fe</a>
    <a class="nav-item" routerLink="/knowus">Conócenos</a>
    <a class="nav-item" routerLink="/resources">Recursos</a>
    <a class="nav-item" routerLink="/">Contacto</a>
  </nav>
</div>