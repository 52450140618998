import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internalheader',
  templateUrl: './internalheader.component.html',
  styleUrls: ['./internalheader.component.css']
})
export class InternalHeaderComponent {

  constructor() { }


}
