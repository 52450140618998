<section class="reuniones">
    <div class="contenedor-reuniones">
        <h2 class="titulo aos-init" data-aos="fade-down">Nuestras Reuniones</h2>
        <div class="imgs-reuniones">
            <div class="img-reunion aos-init" data-aos="zoom-in">
                <img src="\assets\img\reuniongeneral.jpg">
                <div class="img-efecto">
                    <p>Reunión General</p>
                    <span>Domingo 10:30hs</span>
                    <span>José Ingenieros 418, Villa María, Córdoba</span>
                </div>                              
            </div>
            <div class="img-reunion aos-init" data-aos="zoom-in">
                <img src="\assets\img\img-escdom.jpeg">
                <div class="img-efecto">
                    <p>Escuela Dominical</p>
                    <span>Si queres participar de nuestra Escuela Dominical envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.</span>
                </div>
            </div>
            <div class="img-reunion aos-init" data-aos="zoom-in">
                <img src="\assets\img\reunioncasera.jpg">
                <div class="img-efecto">
                    <p>Reuniones Caseras</p>
                    <span>Si queres participar de nuestras Reuniones Caseras envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.</span>
                </div>
            </div>
            <div class="img-reunion aos-init" data-aos="zoom-in">
                <img src="\assets\img\ninos.jpg">
                <div class="img-efecto">
                    <p>Reunión de Niños</p>
                    <span>Si queres participar de nuestras Reuniones de Niños envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.</span>
                </div>
            </div>
            <div class="img-reunion aos-init" data-aos="zoom-in">
                <img src="\assets\img\mujeres.png">
                <div class="img-efecto">
                    <p>Reunión de Mujeres</p>
                    <span>Si queres participar de nuestras Reuniones de Mujeres envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.</span>
                </div>
            </div>
            <div class="img-reunion aos-init" data-aos="zoom-in">
                <img src="\assets\img\hombres.jpg">
                <div class="img-efecto">
                    <p>Reunión de Hombres</p>
                    <span>Si queres participar de nuestras Reuniones de Hombres envianos un mensaje a nuestro <a target="_blank" href="https://wa.me/5491199999999">Whatsapp</a>.</span>
                </div>
            </div>
        </div>
    </div>
</section>
