<app-internalheader></app-internalheader>

<section class="declaracion">
    <div class="presentacion">
        <div class="texto-presentacion">
            <h1>Declaración de Fe</h1>
        </div>
        <div class="wave" style="height: 170px; overflow: hidden;">
            <svg viewBox="0 0 500 150" preserveAspectRatio="none"
                style="height: 100%; width: 100%;">
                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                    style="stroke: none; fill: #fff;">
                </path>
            </svg>
        </div>
    </div>
    <div class="contenido">
        <h2>Declaración de fe</h2>
        <p><b>I. LA BIBLIA</b>
            Creemos que tanto el Antiguo como Nuevo Testamento son inspirados por Dios en forma verbal y plenaria. 2° Tim.3: 16; 2° Pe.1: 20-21; He.4: 12.
            Creemos que Las Sagradas Escrituras no contienen errores en los textos originales ni contradicciones, constituyendo así la autoridad suprema y final para la fe y la vida del hombre. Ap.22:18; Sal.119:105; Sal.19:7-10; 2ª Tim.3:16
        </p>
        <p><b>II. DIOS EL PADRE</b>
            Creemos en un Dios y sustentador del universo eternamente, existente en tres personas, Padre, Hijo, y Espíritu Santo. Gn.1:1; He.2:10; Jn.1:3; Col.1:15-19.
        </p>
        <p><b>III. DIOS EL HIJO</b>
            Creemos en la Deidad de Cristo, su nacimiento virginal y su vida sin pecado. Col.2:9; 2ª Cor.5: 21; Is.7: 14.
            Creemos que la absoluta entrega de su vida fue en plena obediencia al propósito del Padre para pagar la deuda del pecado y satisfacer la justicia de Dios. Rom.5:6; Jn.10:17
            Creemos en su resurrección, corporal, su exaltación a la diestra de Dios, su retorno personal y premilenial. 1ª Tes.5: 23; Mt.23:39; Hch.1:3; Fil.2: 9,10.
        </p>            
        <p><b>IV. DIOS EL ESPÍRITU SANTO</b>
            Creemos en la persona del Espíritu Santo, el cual viene a morar en aquellos que por la fe reciben a Cristo como Salvador siendo eternamente salvos. Jn.14:16,17; Ef.1:13-14.
            Creemos que su ministerio actual es preservar este mundo de la actividad satánica, convencer de pecado al hombre, consolar, guiar, enseñar, e interceder por el creyente. 2ª Tes.2: 6; Hch.13:2; Hch.10:20; Jn.14: 26.
        </p> 
        <p><b>V. LA SALVACIÓN</b>
            Creemos en la Salvación como una obra de gracia de Dios, otorgada por la fe al que cree en la obra redentora de Cristo, operando el nuevo nacimiento y el derecho de ser hijo de Dios. Ef.2:8-9; Ti.3:5; Jn.1:12; Jn.5: 24.
        </p>    
        <p><b>VI. EL PECADO</b>
            Creemos que todos los hombres han pecado y son culpables delante de Dios, encontrándose bajo condenación y excluidos de la presencia de Dios. Rom.3:23, 6:23; Ecl.7: 20.
        </p>    
        <p><b>VII. LA IGLESIA</b>
            Creemos que está compuesta por todos los que han creído en Cristo Jesús como salvador personal desde Pentecostés (Hch.2), hasta el arrebatamiento, los que son llamados a vivir una vida de separación de toda práctica y alianza mundanal y pecaminosa. 1ª Cor.12: 12,13; 1ª Tes.4: 3; 1ª Jn.2: 15; Ef.2: 15,16.
            Creemos que la iglesia no es Israel (1 Corintios 10:32), sino que es un misterio que no fue revelado en las edades pasadas (Efesios 3:1-6; 5:32).
            Creemos que la única autoridad suprema de la iglesia es Cristo (1 Corintios 11:3; Efesios 1:22; Colosenses 1:18) y que el liderazgo de la iglesia , los dones, el orden, la disciplina y la adoración son designadas por Él soberanamente por medio de las Escrituras. Los oficios designados por él para servir bajo su autoridad sobre las congregaciones son ancianos (hombres solamente, llamados también obispos, pastores, sobreveedores - Hechos 20:28; Efesios 4:11) y diáconos, los cuales deben cumplir los requisitos Bíblicos para ocupar esta posición (1 Timoteo 3:1-13; Tito 1:5-9; 1 Pedro 5:1-5).
            Creemos que los dones son dados por Dios para la edificación de la Iglesia. No obstante algunas evidentemente fueron limitadas a la iglesia primitiva hasta completar la totalidad de la revelación bíblica. Ef.4:11-13; 1ª Pe.4: 10-11; 1ª Cor.13: 7-12.
            Creemos que la gran comisión de Mt.28: 18-20 es una de las responsabilidades principales de la Iglesia, como también el crecimiento de los creyentes a través de los pastores y líderes. Ef.4:10-16; Mt.28: 18-20.
        </p>    
        <p><b>VIII. LA RESURRECCIÓN</b>
            Creemos en la resurrección corporal de justos e injustos; la eterna dicha de los salvados en gloria; y el eterno y consciente castigo de los perdidos en el infierno. Dn.12:2; Jn.5:28’29; Ap.20:11-13; Hch.24: 15.
        </p>    
        <p><b>IX. EL ARREBATAMIENTO</b>
            Creemos en el arrebatamiento total de los creyentes que conforman la Iglesia Universal de Cristo, como un acontecimiento sobrenatural, inminente y pretribulacional. 1ª Tes.4: 15-16; 1ª Cor.15: 51-57; Jn.14: 1-3.
        </p>    
        <p><b>X. LA SEGUNDA VENIDA</b>
            Creemos en la segunda venida como un hecho posterior a la tribulación donde Cristo vendrá con los creyentes en gloria para establecer su Reino milenial. Mt.24:29-31, 25:31-32; 1ª Pe.3; Ap.19: 11-16.
        </p>
    </div>   
</section>
